import { ModuleGroupEnum } from "@/includes/logic/Modules/types/types";
import i18n from "@/i18n/i18n";

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { snakeCase } from "lodash";

const getGroupIcon = (group: ModuleGroupEnum) => {
  switch (group) {
    case ModuleGroupEnum.UsersModeration:
      return 'how_to_reg'
    case ModuleGroupEnum.MessagesModeration:
      return 'announcement'
    case ModuleGroupEnum.ModerationCommands:
      return 'keyboard_command_key'
    case ModuleGroupEnum.ActivityBoost:
      return 'videogame_asset'
    case ModuleGroupEnum.Extra:
      return 'more_horiz'
    default:
      return ''
  }
}

const getGroupColor = (group: ModuleGroupEnum) => {
  switch (group) {
    case ModuleGroupEnum.UsersModeration:
      return '#7fdc97'
    case ModuleGroupEnum.MessagesModeration:
      return '#d3b8f8'
    case ModuleGroupEnum.ModerationCommands:
      return '#f18d91'
    case ModuleGroupEnum.ActivityBoost:
      return '#ecc7a6'
    case ModuleGroupEnum.Extra:
      return '#6ddce7'
    default:
      return ''
  }
}

export const groupsSelectOptions: Array<SelectOptionData> = Object.values(ModuleGroupEnum).map(group => ({
    label: i18n.t(`module_group_${ snakeCase(group.toLowerCase()) }_title`).toString(),
    value: group,
    icon: {
      name: getGroupIcon(group as ModuleGroupEnum),
      iconPack: 'material-icons',
      color: getGroupColor(group as ModuleGroupEnum)
    }
  })
)

export const findGroupByValue = (group: ModuleGroupEnum) => {
  return groupsSelectOptions.find((g) => g.value === group )
}

export const stringIsModuleGroupEnum = (value: string): value is ModuleGroupEnum =>{
  return Object.values(ModuleGroupEnum).includes(value as ModuleGroupEnum)
}
