






















import { RelatedItem } from "@/includes/types/ModuleRelatedSuggestion";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class RelatedSuggestion extends Vue {
  @Prop() relatedItems!: Array<RelatedItem>
}
