










import ModulesApi from "@/includes/Api/Modules.api";
import { ChatModulesManager, setModules } from "@/includes/logic/Modules/ModulesManager";
import { successNotification } from "@/includes/NotificationService";
import { NewChatConfig } from "@/includes/types/Chat/types";

import { MediaFileData } from "piramis-base-components/src/components/File/types";
import MediaUploader from 'piramis-base-components/src/components/MediaUploader/MediaUploader.vue';

import { Vue, Component, VModel, Emit } from 'vue-property-decorator';

@Component({
  components: {
    MediaUploader
  }
})
export default class ImportModule extends Vue {
  @VModel() isModalOpen!: boolean

  @Emit()
  import(chatConfig: NewChatConfig) {
    return chatConfig
  }

  handleFileUpload(file: MediaFileData) {
    const fd = new FormData()
    fd.append('chat_id', this.$store.getters.chatId)
    fd.append('config', file.blobFile)

    ModulesApi.importModuleConfig(fd)
      .then(({ data }) => {
        // update chat state
        this.$store.dispatch('updateChatState', data)

        // update modules
        setModules(data.chat.modules_config, ChatModulesManager)

        successNotification()

        this.import(data.chat)
      })
  }
}
